import { memo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaTrash, FaWindowClose, FaSearch } from "react-icons/fa";
import { getQuoatationType, getQuotationStatus, getCRMList } from '../../reducers/requestHandler';
import { Box, FormControlLabel, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, Typography, Button, ListSubheader, InputAdornment  } from '@mui/material';
import { Autocomplete, TextField, SelectField, DateTimePicker, SearchSelectField } from '../../style-guide';

const QSHeader = (props) => {
    const dispatch = useDispatch();
    const { crmList } = useSelector(state => state.crm);
    const { commonFilterData, countryList, listOfcurrency, listOfAirPort, listOfPort, qsTypeList, qsStatusList, listOfIncoterms, listOfServiceType } = useSelector(state => state.parameter);
    const { admin } = useSelector(state => state.session);
    const { roles, adminList, users } = useSelector(state => state.admin);
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const onHandleOpenModal = () => setOpenOrderModal(true);
    const onHandleCancelModal = () => setOpenOrderModal(false);
    const crmListOpt = crmList.map(sender => ({
        id: sender.id,
        name: sender.companyName
    }));
    const crmListOpt1 = [{
        id: -1,
        name: 'All'
    }].concat(crmListOpt)
    const qsTypeListOpt = qsTypeList.map(sender => ({
        id: sender.id,
        name: sender.lable
    }));
    const qsTypeListOpt1 = [{
        id: -1,
        name: 'Quotation Type'
    }].concat(qsTypeListOpt)

    const [qsTypeListOpt2, setQsTypeListOpt2] = useState(qsTypeListOpt1);

    const qsStatusListOpt = qsStatusList.map(sender => ({
        id: sender.id,
        bSelected: sender.bSelected,
        name: sender.lable
    }));

    const qsStatusListOpt1 = [{
        id: -1,
        name: 'Status'
    }].concat(qsStatusListOpt)

    const [qsStatusListOpt2, setQsStatusListOpt2] = useState(qsStatusListOpt1);
    
    const adminListOpt = adminList.map(sender => ({
        id: sender.id,
        bSelected: sender.bSelected,
        name: sender.adminName
    }));
    const adminListOpt1 = [{
        id: -1,
        name: 'Users'
    }].concat(adminListOpt)


    const [adminListOpt2, setAdminListOpt2] = useState(adminListOpt1);

    const listOfcurrencyOpt = listOfcurrency.map(sender => ({
        id: sender.id,
        name: sender.lable
    }));
    const listOfcurrencyOpt1 = [{
        id: -1,
        name: 'Currency'
    }].concat(listOfcurrencyOpt)


    const [listOfcurrencyOpt2, setListOfcurrencyOpt2] = useState(listOfcurrencyOpt1);



    const listOfServiceTypeOpt = listOfServiceType.map(sender => ({
        id: sender.id,
        name: sender.serviceType
    }));
    const listOfServiceTypeOpt1 = [{
        id: -1,
        name: 'Service Type'
    }].concat(listOfServiceTypeOpt)


    const [listOfServiceTypeOpt2, setListOfServiceTypeOpt2] = useState(listOfServiceTypeOpt1);




    const listOfIncotermsOpt = listOfIncoterms.map(sender => ({
        id: sender.id,
        name: sender.incoterm
    }));
    const listOfIncotermsOpt1 = [{
        id: -1,
        name: 'Incoterms'
    }].concat(listOfIncotermsOpt)


    const [listOfIncotermsOpt2, setListOfIncotermsOpt2] = useState(listOfIncotermsOpt1);



    
    const countryList1 = [{
        id: -1,
        name: 'All'
    }].concat(countryList)
    var t = [/*{
        id: -2,
        name: 'Air Port'
    }, */{
        id: -1,
        name: 'All'
    }].concat(listOfAirPort)
    const listOfPortOpt = listOfPort.map(sender => ({
        id: t.length + 1 + sender.id,
        name: sender.name
    }));
    var t1 = [{
        id: -3,
        name: 'Port'
    }].concat(listOfPortOpt)
    const airPortAndPorts = t.concat(t1)
    const [checked, setChecked] = useState([]);
    const refresh = (event) => {
        var t = []
        var selected = [...qsTypeListOpt2]
        for (var i = 0; i < selected.length; i++) {
            var tObj = JSON.parse(JSON.stringify(selected[i]));
            tObj.bSelected = false
            t.push(tObj)
        }
        setQsTypeListOpt2(t)
        props.setFilterData(prev => ({ ...prev, quoteType: t }))
        props.reset()
    }
    const [createDateTime, setCreateDateTime] = useState(0);
    //const [createDateTime, setCreateDateTime] = useState(new Date(new Date().getTime()).getTime());
    const handleCreateDate = (event) => {
        props.setFilterData(prev => ({ ...prev, createdDate: createDateTime }))
    }
    const clearFilterDate = (event) => {
        setCreateDateTime(0)
        props.setFilterData(prev => ({ ...prev, createdDate: 0 }))
    }
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };
    const [isLoaded, setIsLoaded] = useState(false);
    if (!isLoaded && admin.data.session && crmList && crmList.length == 0) {
        //getCRMList(dispatch, admin);
        setIsLoaded(true);
    }
    var isChecked = (item) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";
    const [searchText, setSearchText] = useState("");
    const [optionsItems, setOptItems] = useState(adminListOpt2);
    return (
        <TableHead>
            <TableRow>
                <TableCell style={{ width: '1vw', padding: '2px' }}>
                </TableCell>
                <TableCell style={{ width: '4.3vw', padding: '2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                        <TextField
                            lg={12}
                            value={props.filterData.id}
                            onChange={event => props.setFilterData(prev => ({ ...prev, id: event.target.value }))}
                            label='Id'
                        />
                    </Typography >
                </TableCell>
                <TableCell style={{ width: '4vw', padding: '2px' }} className="quotType_header">
                    <Typography variant='body1' component='span' variant='h7'>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                {'Quotation Type'}
                            </InputLabel>
                            <Select
                                value={props.filterData.quoteType1 || 'Quotation Type'}
                                label={'Quotation Type'}
                                inputProps={{ sx: { fontSize: '11px', padding: '6px' } }}
                               
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {qsTypeListOpt2?.map((option, index) => (
                                    <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>
                                        {option.id != -1 &&
                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                    var t = []
                                                    var selected = [...qsTypeListOpt2]
                                                    for (var i = 0; i < selected.length; i++) {
                                                        var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                        if (tObj.id == option.id) {
                                                            tObj.bSelected = e.target.checked
                                                        }
                                                        t.push(tObj)
                                                    }
                                                    setQsTypeListOpt2(t)
                                                    props.setFilterData(prev => ({ ...prev, quoteType: t }))
                                                    e.stopPropagation();
                                                }} />
                                            </Grid>
                                        }
                                        {
                                            (option.id == -1 ? (option.name) : '')
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        </Typography>
                </TableCell>
                <TableCell style={{ minWidth: '10vw', padding: '2px' }} className="Cutomer_header">
                    
                    <Typography variant='body1' component='span' variant='h7'>
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            {false&&<SearchSelectField
                                required
                                value={props.filterData.quoteType1 || 'Customer'}
                                lg={12}
                                onChange={event => {
                                    props.setFilterData(prev => ({ ...prev, quoteType1: event.target.value, cutomerCrmId: event.target.value }))
                                }}
                                label='Customer'
                                options={crmListOpt1}
                            />}
                            {<Autocomplete
                                required
                                value={props.filterData.quoteType1 || 'Customer'}
                                lg={12}
                                onChange={event => {
                                    props.setFilterData(prev => ({ ...prev, quoteType1: event.target.value, cutomerCrmId: event.target.value }))
                                }}
                                label='Customer'
                                options={crmListOpt1}
                            />}
                        </Grid>
                    </Typography>
                </TableCell>
                {/*<TableCell style={{ width: '7.3vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <TextField
                        lg={12}
                        value={props.filterData.total}
                        onChange={event => props.setFilterData(prev => ({ ...prev, total: event.target.value }))}
                        label='Total'
                        />
                        </Typography >
                </TableCell>*/}
               {/* <TableCell style={{ width: '7.3vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <TextField
                        lg={12}
                            value={props.filterData.originalPrice}
                            onChange={event => props.setFilterData(prev => ({ ...prev, originalPrice: event.target.value }))}
                            label='Original'
                        />
                        </Typography >
                </TableCell>*/}

                <TableCell style={{ minWidth: '10vw', padding: '2px' }} className="Cutomer_header">
                    <Typography variant='body1' component='span' variant='h7'>Total</Typography>
                </TableCell>
                <TableCell style={{ minWidth: '3.5vw', padding: '2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>Margin</Typography>
                    {/*<Typography variant='body1' component='span' variant='h7'>
                    <TextField
                        lg={12}
                        value={props.filterData.margin}
                            onChange={event => props.setFilterData(prev => ({ ...prev, margin: event.target.value }))}
                            label='Margin'
                        />
                    </Typography >*/}
                </TableCell>
                <TableCell style={{ width: '7.3vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    {/*<SelectField
                        required
                        value={props.filterData.countryId}
                        lg={12}
                        onChange={event => props.setFilterData(prev => ({ ...prev, countryId: event.target.value }))}
                        label='Country'
                        options={countryList1}
                        />*/}

                        <Autocomplete
                            required
                            value={props.filterData.countryId}
                            lg={12}
                            onChange={event => props.setFilterData(prev => ({ ...prev, countryId: event.target.value }))}
                            label='Country From'
                            options={countryList1}
                        />
                        </Typography>
                </TableCell>
                <TableCell style={{ minWidth: '4.3vw', padding: '2px' }} className="Cutomer_header">
                    <Typography variant='body1' component='span' variant='h7'>
                        <Grid item lg={12}>
                            {<Autocomplete
                                required
                                value={props.filterData.from}
                                lg={12}
                                onChange={(event) => {
                                    if (event?.target?.value) {
                                        var id = event.target.value == -2 ? -1 : event.target.value;
                                        if (!id) {
                                            id = -1
                                        }
                                        var exch = airPortAndPorts.find(u => u.id === id);
                                        props.setFilterData(prev => ({ ...prev, from: exch.name }));
                                    }
                                }}
                                label='From'
                                options={airPortAndPorts}
                            />}
                        </Grid>
                    </Typography>
                </TableCell>
                {/*{<TableCell style={{ minWidth: '4.3vw', padding: '2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <SelectField
                        required
                        value={props.filterData.from}
                        lg={12}
                        propKey={'name'}
                            onChange={event => {
                                props.setFilterData(prev => ({ ...prev, from: event.target.value }))
                            }}
                        label='From'
                        options={airPortAndPorts}
                        />
                        </Typography>
                </TableCell>
                }*/}
                <TableCell style={{ width: '7.3vw', padding: '2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                        <Autocomplete
                            value={props.filterData.countryToId}
                            lg={12}
                            onChange={event => props.setFilterData(prev => ({ ...prev, countryToId: event.target.value }))}
                            label='Country To'
                            options={countryList1}
                        />
                    </Typography>
                </TableCell>
                <TableCell style={{ minWidth: '4.3vw', padding: '2px' }} className="Cutomer_header">
                    <Typography variant='body1' component='span' variant='h7'>
                        <Grid item lg={12}>
                            {<Autocomplete
                                required
                                value={props.filterData.to}
                                lg={12}
                                onChange={(event) => {
                                    if (event?.target?.value) {
                                        var id = event.target.value == -2 ? -1 : event.target.value;
                                        if (!id) {
                                            id = -1
                                        }
                                        var exch = airPortAndPorts.find(u => u.id === id);
                                        props.setFilterData(prev => ({ ...prev, to: exch.name }));
                                    }
                                }}
                                label='To'
                                options={airPortAndPorts}
                            />}
                        </Grid>
                    </Typography>
                </TableCell>
                {/*{<TableCell style={{ width: '4.3vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <SelectField
                        required
                        value={props.filterData.to}
                        lg={12}
                        propKey={'name'}
                        onChange={event => props.setFilterData(prev => ({ ...prev, to: event.target.value }))}
                        label='To'
                        options={airPortAndPorts}
                        />
                        </Typography>
                </TableCell>
                }*/}
                <TableCell style={{ width: '7.3vw', padding: '2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                            <FormControl fullWidth>
                                <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                    {'Users'}
                                </InputLabel>
                                <Select
                                    value={props.filterData.userId1 || 'User'}
                                    label={'Users'}
                                    inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}

                                    size='small'
                                    MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}
                                    onClose={function () { setOptItems(adminListOpt2); setSearchText("") }}
                                >
                                    <ListSubheader sx={{ width: '100%', padding: '4px', paddingBottom: 1 }}>
                                        <TextField
                                            value={searchText || ''}
                                            lg={12}
                                            autoFocus
                                            placeholder="Type to search..."
                                            fullWidth
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start"></InputAdornment>
                                            }}
                                            onChange={function (e) {
                                                setSearchText(e.target.value)
                                                var t = []
                                                for (var i = 0; i < adminListOpt2.length; i++) {
                                                    var tObj = JSON.parse(JSON.stringify(adminListOpt2[i]));
                                                    if (tObj.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                                                        t.push(tObj)
                                                    }

                                                }
                                                if (t.length > 0) {
                                                    setOptItems(t)
                                                } else {
                                                    setOptItems(optionsItems)
                                                }

                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {
                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                    e.stopPropagation();
                                                }
                                            }}
                                        />
                                    </ListSubheader>
                                    {optionsItems?.map((option, index) => (
                                        <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                            {option.id != -1 &&
                                                <Grid item xs={12} sm={6} md={4} lg={12}>
                                                    <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                        var t = []
                                                        var selected = [...adminListOpt2]
                                                        for (var i = 0; i < selected.length; i++) {
                                                            var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                            if (tObj.id == option.id) {
                                                                tObj.bSelected = e.target.checked
                                                            }
                                                            t.push(tObj)
                                                        }
                                                        setOptItems(t)
                                                        setAdminListOpt2(t)
                                                        props.setFilterData(prev => ({ ...prev, userId: t }))
                                                        e.stopPropagation();
                                                    }} />
                                                </Grid>
                                            }
                                            {
                                                (option.id == -1 ? (option.name) : '')
                                            }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Typography>
                </TableCell>
                <TableCell style={{ width: '5w',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                        <Grid item xs={12} sm={6} md={4} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 45px)', fontSize: '0.88em' }} >
                                {'Status'}
                            </InputLabel>
                            <Select
                                value={props.filterData.status1 || 'Status'}
                                label={'Status'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {qsStatusListOpt2?.map((option, index) => (
                                    <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                        {option.id != -1 &&
                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                    var t = []
                                                    var selected = [...qsStatusListOpt2]
                                                    for (var i = 0; i < selected.length; i++) {
                                                        var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                        if (tObj.id == option.id) {
                                                            tObj.bSelected = e.target.checked
                                                        }
                                                        t.push(tObj)
                                                    }
                                                    setQsStatusListOpt2(t)
                                                    props.setFilterData(prev => ({ ...prev, status: t }))
                                                    e.stopPropagation();
                                                }} />
                                            </Grid>
                                        }
                                        {
                                            (option.id == -1 ? (option.name) : '')
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        </Typography>
                </TableCell>
                <TableCell style={{ width: '5vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <Grid item style={{ width: '5vw' }}>
                        <FormControl fullWidth>
                            <InputLabel id={'Type'} sx={{ top: 4, maxWidth: '7vw', fontSize: '0.88em' }} >
                                {'Incoterms'}
                            </InputLabel>
                            <Select
                                value={props.filterData.incoId1 || 'Incoterms'}
                                label={'Status'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px', maxWidth: '7vw' } }}
                                
                                size='small'
                                    MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>

                                {listOfIncotermsOpt2?.map((option, index) => (
                                    <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputprops={{ sx: { fontSize: '0.7em' } }}>
                                        {option.id != -1 &&
                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                    var t = []
                                                    var selected = [...listOfIncotermsOpt2]
                                                    for (var i = 0; i < selected.length; i++) {
                                                        var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                        if (tObj.id == option.id) {
                                                            tObj.bSelected = e.target.checked
                                                        }
                                                        t.push(tObj)
                                                    }
                                                    setListOfIncotermsOpt2(t)
                                                    props.setFilterData(prev => ({ ...prev, incoId: t }))
                                                    e.stopPropagation();
                                                }} />
                                            </Grid>
                                        }
                                        {
                                            (option.id == -1 ? (option.name) : '')
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        </Typography>
                </TableCell>
                
                {/*<TableCell style={{ width: '4.3vw',padding:'2px' }}>
                    <Typography variant='body1' component='span' variant='h7'>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                {'Currency'}
                            </InputLabel>
                            <Select
                                    value={props.filterData.currency1 || 'Currency'}
                                label={'Currency'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                                
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {listOfcurrencyOpt2?.map((option, index) => (
                                    <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                        {option.id != -1 &&
                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                    var t = []
                                                    var selected = [...listOfcurrencyOpt2]
                                                    for (var i = 0; i < selected.length; i++) {
                                                        var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                        if (tObj.id == option.id) {
                                                            tObj.bSelected = e.target.checked
                                                        }
                                                        t.push(tObj)
                                                    }
                                                    setListOfcurrencyOpt2(t)
                                                    props.setFilterData(prev => ({ ...prev, currency: t }))
                                                    e.stopPropagation();
                                                }} />
                                            </Grid>
                                        }
                                        {
                                            (option.id == -1 ? (option.name) : '')
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        </Typography>
                </TableCell>*/}
                <TableCell style={{ width: '7.3vw', padding: '2px' }} className="Cutomer_header">
                    <Typography variant='body1' component='span' variant='h7'>
                    <Grid item xs={12} sm={6} md={4} lg={12}>
                        <FormControl fullWidth>
                            <InputLabel id={'Type'} sx={{ top: 4, maxWidth: 'calc(100% - 25px)', fontSize: '0.88em' }} >
                                    {'Service Type'}
                            </InputLabel>
                            <Select
                                value={props.filterData.serviceType1 || 'Service Type'}
                                label={'Service Type'}
                                inputProps={{ sx: { fontSize: '0.7em', padding: '6px' } }}
                               
                                size='small'
                                MenuProps={{ sx: { maxHeight: '400px', fontSize: '0.7em' } }}>
                                {listOfServiceTypeOpt2?.map((option, index) => (
                                    <MenuItem key={option.id} disabled={(option.id == -2 || option.id == -3)} style={{ background: (option.id == -2 || option.id == -3) ? 'grey' : '' }} value={option.id} inputProps={{ sx: { fontSize: '0.7em' } }}>
                                        {option.id != -1 &&
                                            <Grid item xs={12} sm={6} md={4} lg={12}>
                                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={option.bSelected} />} label={option.name} onClickCapture={(e) => {
                                                    var t = []
                                                    var selected = [...listOfServiceTypeOpt2]
                                                    for (var i = 0; i < selected.length; i++) {
                                                        var tObj = JSON.parse(JSON.stringify(selected[i]));
                                                        if (tObj.id == option.id) {
                                                            tObj.bSelected = e.target.checked
                                                        }
                                                        t.push(tObj)
                                                    }
                                                    setListOfServiceTypeOpt2(t)
                                                    props.setFilterData(prev => ({ ...prev, serviceType: t }))
                                                    e.stopPropagation();
                                                }} />
                                            </Grid>
                                        }
                                        {
                                            (option.id == -1 ? (option.name) : '')
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        </Typography>
                </TableCell>
                <TableCell style={{ width: '7.3vw', textAlign: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '7.3vw'}}>
                        <DateTimePicker
                            sm={null}
                            md={null}
                            xs={8}

                            label={<Typography fontSize="12px" >Date</Typography>}
                            value={createDateTime||''}
                            onChange={date => setCreateDateTime(date.getTime())}
                            style={{ 'top': '15px', left: '35px', width: '6.3vw', }}
                        />
                        <FaSearch onClick={handleCreateDate} style={{ height: '2.3vh', marginTop: 4, marginLeft: 2, width: '1vw', textAlign: 'center' }} />
                        <FaWindowClose onClick={clearFilterDate} style={{ height: '2.3vh', marginTop: 4, marginLeft: 2, width: '1vw', textAlign: 'center' }} />
                        </Box>
                </TableCell>


            </TableRow>
        </TableHead>
    );
};

export default memo(QSHeader);
